import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import { useAuthStore } from '@/store/auth'
import { useToastStore } from '@/store/toast'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR', 'PELANGGAN'],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'
                    ),
            },

            /**
             * Custom Route For Production
             */
            {
                path: '/m-supplier',
                name: 'Supplier',
                component: () => import('@/views/m_supplier/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LSupplier',
                        component: () => import('@/views/m_supplier/List.vue'),
                    },
                ],
            },
            {
                path: '/m-principal',
                name: 'Principal',
                component: () => import('@/views/m_principal/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LPrinciple',
                        component: () => import('@/views/m_principal/List.vue'),
                    },
                ],
            },
            {
                path: '/harga-obat',
                name: 'HargaObat',
                component: () => import('@/views/harga_obat/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LHargaObat',
                        component: () => import('@/views/harga_obat/List.vue'),
                    },
                ],
            },
            {
                path: '/m-harga',
                name: 'Harga',
                component: () => import('@/views/m_harga/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LHarga',
                        component: () => import('@/views/m_harga/List.vue'),
                    },
                ],
            },
            {
                path: '/m-kategori',
                name: 'Kategori',
                component: () => import('@/views/m_kategori/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LKategori',
                        component: () => import('@/views/m_kategori/List.vue'),
                    },
                ],
            },
            {
                path: '/m-satuan',
                name: 'Satuan',
                component: () => import('@/views/m_satuan/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LSatuan',
                        component: () => import('@/views/m_satuan/List.vue'),
                    },
                ],
            },
            {
                path: '/m-tindakan',
                name: 'Tindakan',
                component: () => import('@/views/m_tindakan/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LTindakan',
                        component: () => import('@/views/m_tindakan/List.vue'),
                    },
                ],
            },
            {
                path: '/m-obat',
                name: 'Obat',
                component: () => import('@/views/m_obat/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
                },
                children: [
                    {
                        path: '',
                        name: 'LObat',
                        component: () => import('@/views/m_obat/List.vue'),
                    },
                    {
                        path: ':id/history-beli',
                        name: 'HistoryBeliObat',
                        component: () =>
                            import('@/views/m_obat/HistoryObat.vue'),
                    },
                ],
            },
            {
                path: '/m-user',
                name: 'User',
                component: () => import('@/views/m_user/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LUser',
                        component: () => import('@/views/m_user/List.vue'),
                        meta: {
                            role: ['SUPER_ADMIN'],
                        },
                    },
                ],
            },
            {
                path: '/m-shift',
                name: 'MShift',
                component: () => import('@/views/m_shift/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LShift',
                        component: () => import('@/views/m_shift/List.vue'),
                        meta: {
                            role: ['SUPER_ADMIN'],
                        },
                    },
                ],
            },
            {
                path: '/pembelian',
                name: 'Pembelian',
                component: () => import('@/views/pembelian/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LPembelian',
                        component: () => import('@/views/pembelian/List.vue'),
                    },
                    {
                        path: 'tambah',
                        name: 'TPembelian',
                        component: () => import('@/views/pembelian/Tambah.vue'),
                        children: [
                            {
                                path: ':id_pembelian/:jenis',
                                name: 'TPembelianDetail',
                                component: () =>
                                    import('@/views/pembelian/Tab.vue'),
                            },
                        ],
                    },
                    {
                        path: 'edit/:id_pembelian',
                        name: 'TEditPembelian',
                        component: () => import('@/views/pembelian/Edit.vue'),
                    },
                ],
            },
            {
                path: '/penjualan',
                name: 'Penjualan',
                component: () => import('@/views/penjualan/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
                },
                children: [
                    {
                        path: '',
                        name: 'LPenjualan',
                        component: () => import('@/views/penjualan/List.vue'),
                    },
                    {
                        path: 'tambah/jual/:nota/:id',
                        name: 'TPenjualan',
                        component: () => import('@/views/penjualan/Tambah.vue'),
                    },
                    {
                        path: 'edit/:id_penjualan/detail',
                        name: 'TEditPenjualan',
                        component: () => import('@/views/penjualan/Edit.vue'),
                    },
                ],
            },
            {
                path: '/setoran',
                name: 'Setoran',
                component: () => import('@/views/setoran/List.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
                },
            },
            {
                path: '/history-pembelian',
                name: 'HistoryPembelian',
                component: () => import('@/views/history/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LHistoryPembelian',
                        component: () => import('@/views/history/List.vue'),
                    },
                    {
                        path: 'show/:id',
                        name: 'ShowHistory',
                        component: () => import('@/views/history/Show.vue'),
                    },
                ],
            },
            {
                path: '/history-penjualan',
                name: 'HistoryPenjualan',
                component: () => import('@/views/history_jual/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
                },
                children: [
                    {
                        path: '',
                        name: 'LHistoryPenjualan',
                        component: () =>
                            import('@/views/history_jual/List.vue'),
                    },
                    {
                        path: 'show/:id',
                        name: 'ShowHistoryJual',
                        component: () =>
                            import('@/views/history_jual/Show.vue'),
                    },
                ],
            },
            {
                path: '/kartu-stok',
                name: 'KartuStok',
                component: () => import('@/views/kartu_stok/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
                },
                children: [
                    {
                        path: '',
                        name: 'LKartuStok',
                        component: () => import('@/views/kartu_stok/List.vue'),
                    },
                    {
                        path: 'show/:kode',
                        name: 'ShowKartuStok',
                        component: () => import('@/views/kartu_stok/Show.vue'),
                    },
                ],
            },
            {
                path: '/laporan',
                name: 'Laporan',
                component: () => import('@/views/laporan/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN'],
                },
                children: [
                    {
                        path: 'setoran',
                        name: 'LaporanSetoran',
                        component: () =>
                            import('@/views/laporan/setoran/TabDetail.vue'),
                    },
                    {
                        path: 'obat',
                        name: 'LaporanObat',
                        component: () =>
                            import('@/views/laporan/obat/TabDetail.vue'),
                    },
                    {
                        path: 'penjualan',
                        name: 'LaporanPenjualan',
                        component: () =>
                            import('@/views/laporan/penjualan/TabDetail.vue'),
                    },
                ],
            },
            {
                path: '/pemesanan',
                name: 'Pemesanan',
                component: () => import('@/views/pemesanan/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'LPemesanan',
                        component: () => import('@/views/pemesanan/List.vue'),
                    },
                    {
                        path: 'tambah',
                        name: 'TPemesanan',
                        component: () => import('@/views/pemesanan/Tambah.vue'),
                    },
                    {
                        path: 'edit/:id_pemesanan/detail',
                        name: 'TEditPemesanan',
                        component: () => import('@/views/pemesanan/Edit.vue'),
                    },
                ],
            },
            {
                path: '/pelanggan/m-obat',
                name: 'Pelanggan',
                component: () => import('@/views/pelanggan/m_obat/Index.vue'),
                meta: {
                    authorized: true,
                    roles: ['PELANGGAN', 'SUPER_ADMIN', 'ADMIN'],
                },
                children: [
                    {
                        path: '',
                        name: 'MObat',
                        component: () =>
                            import('@/views/pelanggan/m_obat/List.vue'),
                        meta: {
                            authorized: true,
                            roles: ['PELANGGAN', 'SUPER_ADMIN', 'ADMIN'],
                        },
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
        meta: {
            authorized: false,
        },
        beforeEnter: (to, from) => {
            // reject the navigation
            const auth = useAuthStore()

            if (auth.getLoggedIn()) {
                return '/'
            }
        },
    },
    {
        path: '/penjualan/print/:id_penjualan',
        name: 'PrintPenjualan',
        component: () => import('@/views/print/Penjualan.vue'),
        meta: {
            authorized: false,
            roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
        },
    },
    {
        path: '/pages',
        redirect: '/pages/404',
        name: 'Pages',
        component: {
            render() {
                return h(resolveComponent('router-view'))
            },
        },
        children: [
            {
                path: '404',
                name: 'Page404',
                component: () => import('@/views/pages/Page404'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

router.beforeEach(async (to, from) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    const auth = useAuthStore()

    if (authRequired && !auth.getLoggedIn()) {
        auth.returnUrl = to.fullPath

        return '/login'
    }

    if (to.meta?.authorized && !to.meta?.roles.includes(auth.getUser().role)) {
        useToastStore().showToast({
            show: true,
            title: 'Notifikasi',
            msg: 'Anda tidak memiliki akses',
            classType: 'bg-warning',
        })

        return from.fullPath
    }
})

export default router
